import type {
  GetStaticPathsContext,
  GetStaticPathsResult,
  GetStaticPropsContext,
} from 'next'
import assignWith from 'lodash/assignWith'
import commerce from '@lib/api/commerce'
import { languageTerritory } from '@commerce/product/use-price'
import { GetProductOperation } from '@commerce/types/product'
import { rootQueryType } from '@shopify/types/component-metafields'
import { ORIGIN_URL, ANKER_COUNTRY_SUFFIX } from '@shopify/const'
import { Pages } from 'templates'
import type { Page } from '@commerce/types/page'
import {
  normalizeHrefLang,
  normalizeConfCollection,
} from '@framework/utils/normalize'
import dynamic from 'next/dynamic'

const HomeCharger = dynamic(() => import('templates/Pages/HomeCharger'))

export async function getStaticProps({
  preview,
  previewData,
  params,
  locale = 'us',
  locales,
}: GetStaticPropsContext<
  { pages: string[] },
  { __handle: string; __preview_type: rootQueryType }
>) {
  const config = { locale, locales }
  let _previewData = {}

  if (preview && previewData && previewData.__preview_type) {
    const { _preview } = await commerce.getMetafields({
      variables: { slug: previewData.__handle },
      config,
      rootQuery: previewData.__preview_type,
      namespace: 'staging',
      keys: ['_preview'],
    })
    _previewData = {
      ..._preview,
      ...previewData,
    }
  }

  const pagesPromise = commerce.getAllPages({
    config,
    previewData: _previewData,
  })
  const { pages } = await pagesPromise
  const path = params?.pages.join('/')
  const slug = '/home' // 默认，首页使用 handle 为 home 的 page

  const pageItem = pages.find((p: Page) =>
    p.handle ? `/${p.handle}` === slug : false
  )
  const data =
    pageItem &&
    (await commerce.getPage({
      variables: { id: pageItem.id! },
      config,
      previewData: _previewData,
    }))
  const page = data?.page

  if (!page) {
    // 404
    return {
      notFound: true,
    }
  }
  // sections metafields
  const sectionMetafields = await commerce.getMetafields({
    previewData: _previewData,
    variables: { slug: data?.page?.handle || '' },
    config,
    rootQuery: 'page',
    namespace: 'section',
    keys: new Array(20).fill(0).map((_, index) => `section_${index + 1}`),
  })

  const order = page?.metafields?.component_order || []
  let componentMetafield: any = []
  if (order.length) {
    componentMetafield = await commerce.getMetafields({
      previewData: _previewData,
      variables: { slug: data?.page?.handle || '' },
      config,
      rootQuery: 'page',
      namespace: 'component',
      keys: order,
    })
  }

  // related products
  const products = page?.metafields?.relatedProducts || []
  // console.log("products",products)
  const productsPromiseList = products?.map((item: any) =>
    commerce.getProduct({
      previewData: _previewData,
      variables: { slug: item.handle },
      config,
    })
  )
  const results: GetProductOperation['data'][] = await Promise.all(
    productsPromiseList || []
  )
  // console.log("results", results)
  const relatedProducts = products.map((product: any, index: any) => ({
    origin: product,
    result: results[index]?.product || {},
  }))
  // console.log("relatedProducts", relatedProducts)

  const siteInfoPromise = commerce.getSiteInfo({
    config,
    previewData: _previewData,
  })
  const { brands, shop, categories, metafields } = await siteInfoPromise

  const mergeMetafields = assignWith(metafields, page.metafields, (obj, page) =>
    !!page ? page : obj
  )

  const languageAlternates = locales?.map((lang) => ({
    hrefLang: languageTerritory(lang || 'us'),
    href: `${ORIGIN_URL}/${lang === 'us' ? '' : lang}`,
  }))

  return {
    props: {
      preview: !!preview,
      previewData: _previewData,
      brands,
      slug,
      page,
      shop,
      navCategories: normalizeConfCollection(metafields, categories),
      pageType: metafields?.pageType?.pageType || 'charger',
      seo: {
        ...page.seo,
        title: page.name,
        canonical: `${ORIGIN_URL}/${locale === 'us' ? '' : locale}`,
        languageAlternates,
        openGraph: {
          ...page.seo,
          title: page.name,
          site_name: 'Anker',
        },
      },
      metafields: {
        ...mergeMetafields,
        sections: sectionMetafields || null,
        components: componentMetafield || null,
      },
      relatedProducts: relatedProducts || [],
    },
    revalidate: 59 * 2,
  }
}

export default HomeCharger
